import { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';

import fetchRentalcarCampaign from '../../fetchers/fetchRentalcarCampaign';

const useRentalcarCampaigns = function (context) {
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        const fetchCampaign = async () => {
            const campaignsResponse = await fetchRentalcarCampaign(context);

            setCampaigns(campaignsResponse || []);
        };

        fetchCampaign();
    }, []);

    return campaigns;
};

const useCurrentCampaign = (campaigns, offer) =>
    useMemo(() => {
        const now = moment();

        if (offer && !offer.discountCouponApplied) return undefined;

        return campaigns
            .filter((campaign) => {
                return (
                    campaign.active &&
                    moment(campaign.start).isSameOrBefore(now) &&
                    moment(campaign.end).isSameOrAfter(now)
                );
            })
            .sort((a, b) => moment(a.start).diff(moment(b.start)))[0];
    }, [campaigns]);

export { useRentalcarCampaigns, useCurrentCampaign };
