import glamorous from 'glamorous';
import options from '@designsystem/options';
import h from 'react-hyperscript';

const FeaturedArticleContainer = glamorous.div({
    display: 'flex',
    gap: options.space.m,
    padding: options.space.m,
    backgroundColor: '#EDF8FF',
    borderRadius: options.radii.small,
});

const FeaturedArticle = glamorous.div({
    display: 'flex',
    gap: options.space.m,
    alignItems: 'center',
    color: 'black',
});

const ArticleIcon = glamorous.img({
    width: 48,
    height: 48,
});

const ArticleTitle = glamorous.div({
    fontWeight: 700,
});

const ArticleDescription = glamorous.div({
    fontSize: options.fontSizes['2xs'],
});

function FeaturedKnowledgeArticle({ title, description, icon, alt }) {
    return h(FeaturedArticleContainer, [
        h(FeaturedArticle, [
            h(ArticleIcon, {
                src: icon,
                alt,
            }),
            h('div', [
                h(ArticleTitle, title),
                h(ArticleDescription, description),
            ]),
        ]),
    ]);
}

export default FeaturedKnowledgeArticle;
