import { useEffect, useState } from 'react';
import moment from 'moment';

import { fetchRentalcarStartSearch } from '../../fetchers/fetchRentalcarStartSearch';
import getCurrentDate from '../../../common/lib/getCurrentDate';
import { noticeError } from '../../lib/newrelic';
import { getLastOutboundFlight } from '../../lib/getLastOutboundFlight';
import { getFirstInboundFlight } from '../../lib/getFirstInboundFlight';

const getDriverData = (hcTravel) => {
    const travellers = hcTravel.booking.travellers;
    const contractualPartner = hcTravel.booking.contractualPartner;

    const contractPartnerIsTraveller = travellers.some(
        (traveller) =>
            contractualPartner.firstName === traveller.firstName &&
            contractualPartner.lastName === traveller.lastName,
    );

    if (!contractPartnerIsTraveller) {
        const firstTraveller = travellers[0];
        return {
            driverage: firstTraveller.age,
            residencyCountryCode: firstTraveller.nationality,
        };
    }

    return {
        driverage: moment(getCurrentDate()()).diff(
            moment(contractualPartner.dateOfBirth),
            'years',
        ),
        residencyCountryCode: contractualPartner.address.countryCode,
    };
};

const createSearchData = (hcTravel) => {
    const lastOutboundFlight = getLastOutboundFlight(hcTravel);
    const firstInboundFlight = getFirstInboundFlight(hcTravel);

    const pickupAirportcode = lastOutboundFlight.arrival.airportCode;
    const pickupDateTime = moment(lastOutboundFlight.arrival.date).add(
        30, // Delays rental period to have enough time an destination airport
        'minutes',
    );

    const dropoffAirportcode = firstInboundFlight.departure.airportCode;
    const dropoffDateTime = moment(firstInboundFlight.departure.date).subtract(
        1, // Users must have enough time to dropoff the car and checkin
        'hours',
    );

    return {
        pickup: {
            airportCode: pickupAirportcode,
            datetime: pickupDateTime.parseZone().format('yyyy-MM-DD HH:mm'),
        },
        dropoff: {
            airportCode: dropoffAirportcode,
            datetime: dropoffDateTime.parseZone().format('yyyy-MM-DD HH:mm'),
        },
        ...getDriverData(hcTravel),
    };
};

/**
 *
 * @param context
 * @param hcTravel
 * @returns {{ search: {
 *              carSearchId: string,
 *              isNewSearch: boolean,
 *              listingUrl: string},
 *             seachData: {
 *              pickup: {
 *                  airportCode: string,
 *                  dateTime: string},
 *              dropoff: {
 *                  airportCode: string,
 *                  dateTime: string},
 *              },
 *              driverage: int, residencyCountryCode: string
 *     }}
 */
export default (context, hcTravel) => {
    const [searchData, setSearchData] = useState(null);
    const [search, setSearch] = useState(null);
    useEffect(() => {
        const startSearch = async () => {
            try {
                const data = createSearchData(hcTravel);
                const response = await fetchRentalcarStartSearch(data, context);

                setSearch(response);
                setSearchData(data);
            } catch (error) {
                context.trackEvent({
                    event: 'event',
                    eventCategory: 'mHCHub - Rentalcar',
                    eventAction: 'searchFailed',
                });
                noticeError(error, {
                    pageName: 'MyHolidaycheck',
                    component: 'RentalcarCard',
                });
            }
        };

        if (hcTravel) {
            startSearch();
        }
    }, [hcTravel]);

    return { search, searchData };
};
