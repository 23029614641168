import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    waveTextLBold,
    waveTextM,
    waveTextMBold,
    waveTextSBold,
} from '../../../../styles/waveText';
import { SectionContainer, SectionTitle } from '../BookingDetails';
import { waveSmMediaQuery } from '../../../../styles/waveMediaQueries';
import { renderContactInfoLink } from './utils';
import { RetalCarBookingType } from '../../BookingPropType';

const FEATURE_PACKAGE_OPTIONS = {
    Basic: 'Basis',
    Better: 'Plus',
    Best: 'HolidayCheck All-Inclusive-Paket',
};

const CARCLASSES = {
    Mini: 'Kleinstwagen',
    Economy: 'Kleinwagen',
    Compact: 'Kompaktklasse',
    Intermediate: 'Mittelklasse',
    Fullsize: 'Oberklasse',
    Premium: 'Premiumklasse',
    Luxury: 'Premiumklasse',
};

const getOption = (featurePackOption) =>
    FEATURE_PACKAGE_OPTIONS[featurePackOption] || FEATURE_PACKAGE_OPTIONS.Basic;

const RentalcarContainer = glamorous.div({
    display: 'grid',
    gap: `${options.space.xl}px`,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
});

const RentalcarImageContainer = glamorous.div({
    gridColumn: 'span 3',
    [waveSmMediaQuery]: {
        gridColumn: 'span 1',
    },
});

const RentalCarImage = glamorous.img({
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
    transform: 'rotateY(180deg)',
    maxWidth: '160px',
    [waveSmMediaQuery]: {
        maxWidth: '180px',
    },
});

const RentalCarInfoContainer = glamorous.div({
    gridColumn: 'span 3',
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.xs}px`,
    [waveSmMediaQuery]: {
        gridColumn: 'span 2',
    },
});

const FullWidtContainer = glamorous.div({
    gridColumn: 'span 3',
});

const Carclass = glamorous.span({
    ...waveTextLBold,
});

const Text = glamorous.span({
    ...waveTextM,
});

const Badge = glamorous.span(({ variant }) => {
    const color =
        {
            Better: '#E5B634',
            Best: options.colors.green,
        }[variant] || '#8892a3';

    return {
        padding: `${options.space['2xs']}px ${options.space.xs}px`,
        borderRadius: options.radii.small,
        backgroundColor: color,
        color: options.colors.white,
        display: 'inline-flex',
        alignItems: 'center',
        ...waveTextSBold,
    };
});

const RentalcarInfo = glamorous.div({
    gridColumn: 'span 3',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    rowGap: `${options.space.m}px`,
    columnGap: `${options.space.xl}px`,
    [waveSmMediaQuery]: {
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
});

const RentalcarInfoVariant = glamorous(RentalcarInfo)({
    borderTop: `1px solid ${options.colors.gray90}`,
    borderBottom: `1px solid ${options.colors.gray90}`,
    padding: `${options.space.m}px 0`,
});

const InfoElement = glamorous.div(({ span }) => ({
    gridColumn: `span ${span}`,
    display: 'flex',
    flexDirection: 'column',
}));
const Bold = glamorous.span({
    ...waveTextMBold,
});

const renderInfo = (title, value, span = 1) =>
    h(InfoElement, { span }, [h(Text, title), h(Bold, value)]);

function Rentalcar({ booking, sectionId, myRef }, { config: { assetsPath } }) {
    const pickup = [
        booking.carOffer.destinationName,
        booking.carOffer.localSupplierName,
        booking.transferType === 'InsideTerminal'
            ? 'im Terminal'
            : 'per Shuttle',
    ]
        .filter((part) => part !== undefined)
        .join(' • ');

    const maindriver = `${booking.customerFirstName}${booking.customerLastName ? ` ${booking.customerLastName}` : ''}`;

    const carClass =
        CARCLASSES[booking.carOffer.carClass] ||
        booking.carOffer.carClass ||
        'Mini';

    return h(SectionContainer, { id: sectionId, innerRef: myRef }, [
        h(RentalcarContainer, [
            h(FullWidtContainer, [h(SectionTitle, 'Dein Mietwagen')]),
            h(RentalcarImageContainer, [
                h(RentalCarImage, { src: booking.carOffer.pictureUrl }),
            ]),
            h(RentalCarInfoContainer, [
                h(Carclass, carClass),
                booking.carOffer.featurePackage !== undefined &&
                    h('div', [
                        h(
                            Badge,
                            { variant: booking.carOffer.featurePackage },
                            getOption(booking.carOffer.featurePackage),
                        ),
                    ]),
                h(Text, 'Unsere Mietwagen-Experten sind für Dich da:'),
                h('div', [
                    renderContactInfoLink(
                        'tel:+498914379153',
                        `${assetsPath}/myBookings/phone.svg`,
                        '+49 89 143 79 153',
                        'Telefonnummer',
                    ),
                    renderContactInfoLink(
                        'mailto:mietwagen@holidaycheck.com',
                        `${assetsPath}/myBookings/email.svg`,
                        'mietwagen@holidaycheck.com',
                        'E-Mail-Adresse',
                    ),
                ]),
            ]),
            h(RentalcarInfoVariant, [
                renderInfo(
                    'Abholung',
                    `${moment(booking.pickupDatetime).format('DD.MM.YYYY - HH:mm')}`,
                ),
                renderInfo(
                    'Rückgabe',
                    `${moment(booking.dropoffDatetime).format('DD.MM.YYYY - HH:mm')}`,
                ),
                renderInfo('Hauptfahrer', maindriver),
                renderInfo('Buchungsnummer', booking.id),
            ]),
            h(RentalcarInfo, [
                renderInfo('Veranstalter', booking.supplierName),
                renderInfo('Mietwagenstation', pickup, 3),
            ]),
        ]),
    ]);
}

Rentalcar.displayName = 'Rentalcar';

Rentalcar.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};
Rentalcar.propTypes = {
    sectionId: PropTypes.string.isRequired,
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    booking: RetalCarBookingType.isRequired,
};

export { Rentalcar };
