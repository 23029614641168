import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveMdMediaQuery } from '../../../styles/waveMediaQueries';

export const Content = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    padding: options.space.xl,
    [waveMdMediaQuery]: {
        padding: `${options.space.xl}px 0 ${options.space.m}px ${options.space.l}px`,
    },
});
