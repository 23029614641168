import options from '@designsystem/options';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import h from 'react-hyperscript';
import { InfoOutline } from '@designsystem/icons';

import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { useCurrentCampaign } from '../useCurrentCampaign';
import { useRentalcarCampaigns } from '../useRentalcarCampaigns';
import Tooltip from '../RentalcarOfferCard/Tooltip';

const BadgeIconWrapper = glamorous.span({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',
});

const DiscountBadgeContainer = glamorous.span({
    borderRadius: options.radii.small,
    padding: `${options.space['2xs']}px ${options.space.xs}px`,
    backgroundColor: options.colors.green,
    color: options.colors.white,
    fontWeight: 700,
    fontSize: options.fontSizes['2xs'],
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: options.space['2xs'],
    flexShrink: 0,
    [waveSmMediaQuery]: {
        display: 'none',
    },
    [waveMdMediaQuery]: {
        display: 'flex',
    },
});

const DiscountBadge = ({
    context,
    withTooltip = false,
    couponApplied = true,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const campaigns = useRentalcarCampaigns(context);
    const current = useCurrentCampaign(campaigns);

    if (current && couponApplied) {
        return h(DiscountBadgeContainer, [
            current.code,
            withTooltip &&
                h(BadgeIconWrapper, [
                    h(InfoOutline, {
                        width: 16,
                        height: 16,
                        fill: options.colors.white,
                        onMouseEnter: () => setShowTooltip(true),
                        onMouseLeave: () => setShowTooltip(false),
                    }),
                    showTooltip &&
                        h(Tooltip, {
                            text: 'Mit Klick auf "Mietwagen finden" wird der Rabatt automatisch bei Deiner Buchung angerechnet.',
                            width: 200,
                        }),
                ]),
        ]);
    }

    return h(Fragment);
};

DiscountBadge.propTypes = {
    context: PropTypes.object.isRequired,
    withTooltip: PropTypes.bool,
};

DiscountBadge.displayName = 'DiscountBadge';

export default DiscountBadge;
