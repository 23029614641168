import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';
import { Calendar, PackageTrip } from '@designsystem/icons';

import SmallCountdown from './Countdown/SmallCountdown';
import { waveHeadlineXS } from '../../../../styles/waveHeadlines';
import { tinyFont } from '../../../../styles/fonts';
import { INPROGRESS, UPCOMING } from './cardTypes';
import { getBackgroundImage, transformDate } from './utils';
import { waveSmMediaQuery } from '../../../../styles/waveMediaQueries';

const BookingCity = glamorous.header({
    ...waveHeadlineXS,
    color: options.colors.white,
});

const BookingDetailsContainer = glamorous.div({
    zIndex: 1,
    textAlign: 'center',
    color: options.colors.white,
});

const HotelAndDatesContainer = glamorous.div({
    ...tinyFont,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: options.colors.white,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
    },
});

const ChildHotelContainer = glamorous.div({
    display: 'flex',
    alignItems: 'center',
});

const ChildDatesContainer = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    marginTop: '-8px',
    marginBottom: `${options.space.xs}px`,
    [waveSmMediaQuery]: {
        marginTop: '0px',
        marginBottom: '0px',
        marginLeft: `${options.space.xs}px`,
    },
});

const UpcomingOrInProgressBooking = ({
    cardType,
    bookingDetails,
    BookingDetailsButton,
    generateBackgroundImageDiv,
    generateOverlayShadeDiv,
}) => {
    const BackgroundImage = generateBackgroundImageDiv({
        height: '400px',
        justifyContent: 'flex-end',
        backgroundImage: getBackgroundImage(bookingDetails.hotelId),
    });
    const OverlayShadeDiv = generateOverlayShadeDiv(0.7);

    const startAndEndDate = `${transformDate(bookingDetails.startDate)} - ${transformDate(bookingDetails.endDate)}`;
    const Countdown =
        cardType === UPCOMING
            ? h(SmallCountdown, { startDate: bookingDetails.startDate })
            : '';

    return h(BackgroundImage, [
        h(OverlayShadeDiv),
        h(BookingDetailsContainer, [
            h(BookingCity, bookingDetails.city),
            Countdown,
            h(HotelAndDatesContainer, [
                h(ChildHotelContainer, [
                    h(PackageTrip, {
                        style: {
                            marginRight: '3px',
                        },
                    }),
                    h('p', bookingDetails.hotelName),
                ]),
                h(ChildDatesContainer, [
                    h(Calendar, {
                        style: {
                            marginRight: '3px',
                        },
                    }),
                    h('p', startAndEndDate),
                ]),
            ]),
            h(
                BookingDetailsButton,
                {
                    href: '/mhc/meine-buchungen',
                },
                'BuchungsDetails',
            ),
        ]),
    ]);
};

export default UpcomingOrInProgressBooking;

UpcomingOrInProgressBooking.propTypes = {
    BookingDetailsButton: PropTypes.func,
    type: PropTypes.oneOf([UPCOMING, INPROGRESS]),
    bookingDetails: PropTypes.exact({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        hotelName: PropTypes.string,
        city: PropTypes.string,
        hotelId: PropTypes.string,
        bookingId: PropTypes.string,
        __typename: PropTypes.string,
    }),
    generateOverlayShadeDiv: PropTypes.func,
    generateBackgroundImageDiv: PropTypes.func,
};
