import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';
// eslint-disable-next-line max-len
import removeClientsideNavigationClickListenersFromDocument from '../lib/removeClientsideNavigationClickListenersFromDocument';
import registerClientsideNavigationInDocument from '../lib/registerClientsideNavigationInDocument';
import appendBaseTag from '../lib/elementModifiers/appendBaseTag';
import appendHiddenInput from '../lib/elementModifiers/appendHiddenInput';
import setHrefFromData from '../lib/elementModifiers/setHrefFromData';
import setTargetSelf from '../lib/elementModifiers/setTargetSelf';
import replaceInAttribute from '../lib/elementModifiers/replaceInAttribute';
import removeAttribute from '../lib/elementModifiers/removeAttribute';
import removeElement from '../lib/elementModifiers/removeElement';
import injectStylesheet from '../lib/elementModifiers/injectStylesheet';
import isFeatureFlagSet from '../../common/lib/isFeatureFlagSet';
import replaceHrefBeginning from '../lib/elementModifiers/replaceHrefBeginning';
import profilePictureStore from '../lib/profilePictureStore';
import resizePicture from '../lib/resizePicture';
import fetchAuthorize from '../fetchers/fetchAuthorize';
import getCurrentDate from '../../common/lib/getCurrentDate';
import TrackerWrapper from '../lib/gav4/TrackerWrapper';
import { noticeError } from '../lib/newrelic';

const ubtVendorId = '5ec515cab8e05c6bd70f429f';
const apiVersion = 2;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoggedIn: null, isAuthorized: null };
        this.loginUser = this.loginUser.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
    }

    async loginUser() {
        const isAuthorized = await fetchAuthorize(this.getChildContext());

        if (
            this.state.isAuthorized !== isAuthorized ||
            !this.state.isLoggedIn
        ) {
            this.setState({
                isLoggedIn: true,
                isAuthorized,
            });
        }
    }

    logoutUser() {
        this.setState({ isLoggedIn: false, isAuthorized: false });
    }

    async componentDidMount() {
        const window = this.props.window;

        window.__tcfapi('addEventListener', apiVersion, function (tcEvent) {
            if (
                tcEvent.eventStatus === 'tcloaded' ||
                tcEvent.eventStatus === 'useractioncomplete'
            ) {
                if (tcEvent.eventStatus === 'useractioncomplete') {
                    window.postMessage('tcfapi.useractioncomplete');
                }

                window.__tcfapi(
                    'getCustomVendorConsents',
                    apiVersion,
                    function (vendorConsents, success) {
                        if (!success) {
                            window.hasConsentedToTrackEvents = false;
                            return;
                        }

                        const grant =
                            vendorConsents.grants[ubtVendorId] &&
                            vendorConsents.grants[ubtVendorId].vendorGrant;

                        if (!grant) {
                            window.hasConsentedToTrackEvents = false;
                            return;
                        }

                        window.hasConsentedToTrackEvents = true;
                    },
                );
            }
        });
        window.addEventListener('hc:login', this.loginUser);
        window.addEventListener('hc:logout', this.logoutUser);

        if (window._hcLoggedIn === true) {
            await this.loginUser();
        } else if (window._hcLoggedIn === false) {
            this.logoutUser();
        }
    }

    componentWillUnmount() {
        const { window } = this.context;

        if (window) {
            window.removeEventListener('hc:login', this.loginUser);
            window.removeEventListener('hc:logout', this.logoutUser);
        } else {
            noticeError(new Error('window is undefined'), {
                component: 'App',
                window: typeof window,
            });
        }
    }

    render() {
        const { isLoggedIn } = this.state;

        if (isLoggedIn === null) {
            return null;
        }

        return h(ApolloProvider, { client: this.props.apolloClient }, [
            h(
                BrowserRouter,
                { basename: this.props.config.pathPrefix },
                h(TrackerWrapper, [h(Routes)]),
            ),
        ]);
    }

    extractUserId() {
        try {
            const cookies = this.props.window.document.cookie;
            const authJwtIdToken = cookies
                .split(';')
                .find((c) => c.includes('AUTH_JWT_ID'));

            if (authJwtIdToken) {
                const arrayToken = authJwtIdToken.split('.');
                // eslint-disable-next-line no-undef
                const tokenPayload = JSON.parse(atob(arrayToken[1]));
                return tokenPayload['https://holidaycheck.com/userId'];
            }

            return '';
        } catch (error) {
            noticeError(error, {
                component: 'App',
            });

            return '';
        }
    }

    getChildContext() {
        const fetch = this.props.window.fetch;
        const config = this.props.config;

        return {
            profilePictureStore,
            resizePicture,
            config,
            document: this.props.window.document,
            fetch,
            getCurrentDate: getCurrentDate(config.staticCurrentTime),
            registerClientsideNavigationInDocument,
            trackEvent: this.props.trackEvent,
            localStorage: this.props.window.localStorage,
            location: this.props.window.location,
            removeClientsideNavigationClickListenersFromDocument,
            window: this.props.window,
            elementModifiers: {
                appendBaseTag,
                appendHiddenInput,
                injectStylesheet,
                removeAttribute,
                removeElement,
                replaceHrefBeginning,
                replaceInAttribute,
                setHrefFromData,
                setTargetSelf,
            },
            isFeatureFlagSet: isFeatureFlagSet(
                this.props.window.location.search,
            ),
            isLoggedIn: this.state.isLoggedIn,
            isAuthorized: this.state.isAuthorized,
            userId: this.extractUserId(),
        };
    }
}

App.childContextTypes = {
    profilePictureStore: PropTypes.object.isRequired,
    resizePicture: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    document: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    getCurrentDate: PropTypes.func.isRequired,
    registerClientsideNavigationInDocument: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    localStorage: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    removeClientsideNavigationClickListenersFromDocument:
        PropTypes.func.isRequired,
    window: PropTypes.object.isRequired,
    elementModifiers: PropTypes.object.isRequired,
    isFeatureFlagSet: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    isAuthorized: PropTypes.bool,
    userId: PropTypes.string,
};

export default App;
