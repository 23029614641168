import { Component } from 'react';
import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';

import BookingsList from './BookingsList';
import { bookingsForUser } from '../../queries/myBookings';
import RedirectWithReload from '../navigation/RedirectWithReload';
import { noticeError } from '../../lib/newrelic';

class BookingsQuery extends Component {
    render() {
        return h(
            Query,
            { fetchPolicy: 'cache-and-network', query: bookingsForUser },
            ({ loading, data, error }) => {
                if (
                    loading ||
                    error ||
                    !data ||
                    !data.bookingsForUser ||
                    !data.bookingsForUser.items
                ) {
                    if (error) {
                        noticeError(error, {
                            component: 'BookingsQuery',
                            userId: this.context.userId,
                        });
                        if (
                            error.networkError &&
                            error.networkError.result &&
                            error.networkError.result.errors
                        ) {
                            error.networkError.result.errors.forEach((e) =>
                                noticeError(e, {
                                    component: 'BookingsQuery',
                                    userId: this.context.userId,
                                }),
                            );
                        }
                    }

                    return null;
                }

                const bookings = data.bookingsForUser.items;

                if (!this.isLocationBookingIdInBookings(bookings)) {
                    return this.redirectToLoginWithBookerHint();
                }

                return h(BookingsList, { bookings });
            },
        );
    }

    redirectToLoginWithBookerHint() {
        const currentPath = `${this.context.location.pathname}${this.context.location.search}`;
        const loginUri = `/login?ref=${encodeURIComponent(currentPath)}&messageId=hintForBooker`;

        return h(RedirectWithReload, { to: loginUri });
    }

    isLocationBookingIdInBookings(bookings) {
        if (!this.context.location.hash) {
            return true;
        }

        // location.hash returns '#' as first character followed by the booking id
        const bookingId = this.context.location.hash.substring(1);
        return bookings.some((booking) => booking.id === bookingId);
    }
}

BookingsQuery.contextTypes = {
    location: PropTypes.object.isRequired,
    userId: PropTypes.string,
};

export default BookingsQuery;
