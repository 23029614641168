import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Fragment, useRef, useState } from 'react';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { pxToRem } from '../../styles/unitConverter';
import { blue100, blue } from '../../styles/waveColors';
import Link from '../navigation/Link';
import getIndexItemTextForSection from './lib/getIndexItemTextForSection';
import bookingDetailSections from './lib/bookingDetailSections';
import { applyOnTabletAndUp, applyOnMobile } from '../../styles/mediaQueries';
import { waveMdMediaQuery } from '../../styles/waveMediaQueries';

const sideMargin = pxToRem(24);

const Details = glamorous.div({
    gridArea: 'content',

    margin: '0 auto',
    width: 'auto',

    [applyOnTabletAndUp]: {
        marginRight: sideMargin,
    },
    [applyOnMobile]: {
        marginInline: pxToRem(20),
    },
});

const IndexItemsList = glamorous.div({
    gridArea: 'sidebar',

    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    gap: pxToRem(16),

    background: blue100,
    borderRadius: pxToRem(4),

    [applyOnTabletAndUp]: {
        marginLeft: sideMargin,
        padding: pxToRem(20),
        position: 'sticky',
        top: pxToRem(24),
        marginBottom: pxToRem(24),
    },
    [applyOnMobile]: {
        marginInline: pxToRem(20),
        padding: pxToRem(16),
    },
});
IndexItemsList.displayName = 'IndexItemsList';

const IndexItem = ({ text, myRef, shouldRender, children }) => {
    return (
        shouldRender &&
        h(
            Link,
            {
                href: '#',
                css: {
                    color: blue,
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: options.space.xs,
                    [waveMdMediaQuery]: {
                        gap: options.space.m,
                    },
                },
                onClick: (e) => {
                    e.preventDefault();

                    // see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
                    myRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                },
            },
            [text, children],
        )
    );
};
IndexItem.propTypes = {
    text: PropTypes.string.isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    shouldRender: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

const IndexHeaderContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',

    width: '100%',
    justifyContent: 'space-between',
});

const MenuButton = glamorous.button({
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
});

const IndexHeader = ({ onExpandClick }, { config: { assetsPath } }) => {
    return h(IndexHeaderContainer, [
        h('b', 'Buchung verwalten'),
        h(
            MenuButton,
            { onClick: onExpandClick },
            h('img', {
                src: `${assetsPath}/myBookings/menu.svg`,
                alt: 'Menü',
            }),
        ),
    ]);
};
IndexHeader.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

const Index = ({ children, startsExpanded }) => {
    const [expanded, setExpanded] = useState(startsExpanded);
    const onExpandClick = () => setExpanded(!expanded);

    return h(
        IndexItemsList,
        [h(IndexHeader, { onExpandClick })].concat(expanded ? children : []),
    );
};
Index.propTypes = {
    startsExpanded: PropTypes.bool.isRequired,
};

const makeSection = (component) => ({
    component,
    indexEntryText: getIndexItemTextForSection(component.displayName),
    ref: useRef(null),
});

function BookingDetails({ booking, showDetails, isMobile }, context) {
    if (!showDetails) {
        return null;
    }

    const sections = bookingDetailSections
        .map(makeSection)
        .filter((section) =>
            section.component.displayWhen
                ? section.component.displayWhen(booking)
                : true,
        );

    const indexEntries = [];
    const sectionEntries = [];

    for (const { component, ref, indexEntryText } of sections) {
        const [sectionWillRender, setWillRender] = useState(false);

        sectionEntries.push(
            h(component, {
                booking,
                myRef: ref,
                setWillRender,
            }),
        );

        indexEntries.push(
            h(
                IndexItem,
                {
                    text: indexEntryText,
                    myRef: ref,
                    shouldRender: sectionWillRender,
                    key: `index_item_${indexEntryText}`,
                },
                [component.menuBadge && h(component.menuBadge, { context })],
            ),
        );
    }

    return h(Fragment, [
        h(Index, { startsExpanded: !isMobile }, indexEntries),
        h(Details, sectionEntries),
    ]);
}

BookingDetails.propTypes = {
    booking: PropTypes.object.isRequired,
    showDetails: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

BookingDetails.contextTypes = {
    fetch: PropTypes.func,
    config: PropTypes.object,
};

export default BookingDetails;
