import { noticeError } from './newrelic';

const handleQueryErrors = ({ error, userId, component }) => {
    try {
        if (error.graphQLErrors && error.graphQLErrors.length) {
            error.graphQLErrors.forEach(({ message }) => {
                noticeError(message, {
                    component,
                    errorCategory: 'GraphQLError',
                    userId,
                });
            });
        } else if (error.networkError) {
            const hasErrors = Boolean(
                error.networkError.result &&
                    error.networkError.result.errors.length,
            );

            if (hasErrors) {
                error.networkError.result.errors.forEach(({ message }) => {
                    noticeError(message, {
                        component,
                        errorCategory: 'NetworkError',
                        userId,
                    });
                });
            }
        } else {
            noticeError(error.message, {
                component,
                errorCategory: 'GeneralError',
                userId,
            });
        }
    } catch (e) {
        noticeError(e.message, {
            component: 'MyPremiumCard',
            errorCategory: 'ErrorHandling',
            userId,
        });
    }
};

export default handleQueryErrors;
