import { useEffect } from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { getAdvantages, getCardConfigKey } from './config';
import { RentalcarCardContainer } from '../components/RentalcarCardContainer';
import CarWheel from '../../icons/CarWheel';
import { waveTextMMedium, waveTextS } from '../../../styles/waveText';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { waveHeadline2XS } from '../../../styles/waveHeadlines';
import { SlimCheckmark } from '../../icons/Checkmark';
import useRentalcarSearch from '../useRentalcarSearch';
import useSearchOffer from '../useSearchOffer';
import { RentalcarButton } from '../components/RentalcarButton';
import { renderImage } from '../components/RentalcarImage';
import { RentalcarSearchOffer } from '../RentalcarSearchOffer';
import DiscountBadge from '../components/DiscountBadge';

const CardContainer = glamorous(RentalcarCardContainer)({
    borderRadius: options.radii.small,
    fontFamily: 'Montserrat',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: 0,
    position: 'relative',
    [waveMdMediaQuery]: {
        // override base style from RentalcarCardContainer
        margin: 0,
    },
    [waveLgMediaQuery]: {
        margin: 0,
        flexDirection: 'row',
    },
});

const SmallTitle = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    gap: options.space.xs,
    ...waveTextMMedium,
    color: options.colors.green40,
    fontWeight: 600,
});

const Title = glamorous.span({
    ...waveHeadline2XS,
});

const Content = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    padding: options.space.xl,
    [waveMdMediaQuery]: {
        flexGrow: 1,
        flexShrink: 0,
        padding: `${options.space.xl}px 0 ${options.space.m}px ${options.space.l}px`,
    },
});

const AdvantagesContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
        alignItems: 'end',
        gap: options.space.xl,
    },
});

const Advantages = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space['2xs'],
    width: 'fit-content',
});
const Advantage = glamorous.span({
    display: 'flex',
    alignItems: 'center',
    gap: options.space['2xs'],
});
const AdvantageText = glamorous.span({
    ...waveTextS,
});

const renderAdventage = (text) =>
    h(Advantage, [
        h(
            glamorous(SlimCheckmark)({
                color: options.colors.white,
                flexShrink: 0,
            }),
        ),
        h(AdvantageText, text),
    ]);

const HCMietwagenLogo = glamorous.img({
    position: 'absolute',
    right: options.space.m,
    top: options.space.m,
    width: 110,
    [waveMdMediaQuery]: {
        right: options.space.xl,
        top: options.space.xl,
        width: 134,
    },
});

const ButtonSection = glamorous.div({
    display: 'flex',
    gap: options.space.m,
    flexDirection: 'column-reverse',
    [waveSmMediaQuery]: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    [waveMdMediaQuery]: {
        gap: options.space.xl,
    },
});

export const renderTitleAndAdvantages = ({
    advantages,
    title = 'Unsere Mietwagen-Empfehlung',
}) => [
    h('div', [
        h(SmallTitle, [
            h(CarWheel, { css: { width: '16px' } }),
            h('span', 'Nur hier und nur für Dich'),
        ]),
        h(Title, title),
    ]),
    h(AdvantagesContainer, [h(Advantages, advantages.map(renderAdventage))]),
];

const ButtonText = glamorous.span({
    '& .button-prefix': {
        display: 'none',
    },
    [waveMdMediaQuery]: {
        '& .button-prefix': {
            display: 'inline',
        },
    },
});

const defaultSearchOffer = ({
    context,
    hasCampaign,
    defaultOfferCardTitle,
    buttonPrefix,
}) => {
    const baseButtonText = 'Mietwagen finden';
    const buttonText = h(ButtonText, [
        buttonPrefix &&
            h('span', { className: 'button-prefix' }, [buttonPrefix, ' ']),
        baseButtonText,
    ]);

    return h(CardContainer, [
        h(Content, [
            h(
                renderTitleAndAdvantages({
                    advantages: getAdvantages(
                        hasCampaign ? 'defaultVoucher' : 'default',
                    ),
                    title: defaultOfferCardTitle,
                }),
            ),
            h(ButtonSection, [
                h(
                    RentalcarButton,
                    {
                        href: `/mietwagen?utm_source=mHC&utm_medium=Hub&utm_campaign=TravelNoCarNo&b2bPartnerSource=hc_mhc_${context.config.topLevelDomain}`,
                    },
                    buttonText,
                ),
                hasCampaign &&
                    h(DiscountBadge, {
                        context,
                        withTooltip: true,
                        couponApplied: true,
                    }),
            ]),
        ]),
        h(
            renderImage(
                'default',
                `${context.config.assetsPath}/hcMietwagen/default.jpg`,
            ),
        ),
        h(HCMietwagenLogo, {
            src: `${context.config.assetsPath}/hcMietwagen/holidayCheckMietwagenLogo.svg`,
        }),
    ]);
};

function RentalcarOfferCard({
    hcTravel,
    context,
    showDefaultSearchOffer,
    utmParameter,
    eventCategory,
    offerCardTitle,
    defaultOfferCardTitle,
    buttonPrefix,
    hasCampaign,
}) {
    if (showDefaultSearchOffer) {
        return defaultSearchOffer({
            context,
            hasCampaign,
            defaultOfferCardTitle,
            buttonPrefix,
        });
    }
    const cardConfigKey = getCardConfigKey(hcTravel.booking.travellers);

    useEffect(() => {
        context.trackEvent({
            event: 'event',
            eventCategory,
            eventAction: `displayCard=${cardConfigKey}`,
            eventLabel: 'Rentalcar - Offercard',
        });
    }, []);

    const { search, searchData } = useRentalcarSearch(context, hcTravel);

    const { offer, isLoading } = useSearchOffer(
        search,
        hcTravel,
        false,
        context,
    );

    const advantages = getAdvantages(cardConfigKey);

    if (search) {
        return h(RentalcarSearchOffer, {
            advantages,
            isLoading,
            offer,
            cardConfigKey,
            hcTravel,
            search,
            searchData,
            context,
            utmParameter,
            hasCampaign,
            offerCardTitle,
        });
    }

    return h(RentalcarOfferCard, {
        hcTravel,
        context,
        showDefaultSearchOffer: true,
        eventCategory,
        offerCardTitle,
        defaultOfferCardTitle,
        hasCampaign,
    });
}

export { RentalcarOfferCard };
