import h from 'react-hyperscript';
import { useQuery } from '@apollo/client';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import UpcomingOrInProgressBooking from './UpcomingOrInProgressBooking';
import NoBookingCard from './NoBookingCard';
import { nextOrMostRecentActiveBooking } from '../../../../queries/nextOrMostRecentActiveBooking';
import { UPCOMING, RECENT, INPROGRESS, NONE } from './cardTypes';
import RecentBooking from './RecentBooking';
import { waveTextMMedium } from '../../../../styles/waveText';

const CardContainer = glamorous.div({
    height: '400px',
});

const ReusableButton = glamorous.a({
    ...waveTextMMedium,
    color: options.colors.white,
    borderColor: options.colors.white,
    fontWeight: options.fontWeights.bold,
    textDecoration: 'none',
    marginBottom: `${options.space.xl}px`,
    height: '40px',
    minWidth: '112px',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: options.radii.full,
    cursor: 'pointer',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'center',
    paddingLeft: `${options.space.l}px`,
    paddingRight: `${options.space.l}px`,
    textTransform: 'uppercase',
    transition: 'all 0.3s ease',
    background: 'transparent',
    '&:not(:disabled):hover': {
        background: options.colors.gray100,
        borderColor: options.colors.gray100,
        color: options.colors.blue20,
    },
    '&:focus': {
        color: options.colors.blue,
        outlineOffset: 'unset',
    },
    zIndex: 1,
});

/**
 * @param {Float} opacity - Number between 0 and 1 (inclusive)
 * @returns Glamorous Div - Component which could be reused to provide a shade of black on top of images, parent should have position relative
 */
const generateOverlayShadeDiv = (opacity) =>
    glamorous.div({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, ${opacity}) 100%)`,
    });

/**
 * @param {Object} styles - Stylesheet object
 * @returns Glamorous Div - Component which could be reused to create a div with a background image
 */
const generateBackgroundImageDiv = (styles) =>
    glamorous.div({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        alignItems: 'center',
        ...styles,
    });

const BookingsCard = () => {
    const { loading, error, data } = useQuery(nextOrMostRecentActiveBooking);

    if (loading) {
        return h(CardContainer);
    }

    if (error || data.nextOrMostRecentActiveBooking.cardType === NONE) {
        return h(NoBookingCard, {
            CardContainer,
            FindYourPerfectVacationButton: ReusableButton,
            generateOverlayShadeDiv,
            generateBackgroundImageDiv,
        });
    }

    if (
        data.nextOrMostRecentActiveBooking.cardType === INPROGRESS ||
        data.nextOrMostRecentActiveBooking.cardType === UPCOMING
    ) {
        return h(UpcomingOrInProgressBooking, {
            BookingDetailsButton: ReusableButton,
            cardType: data.nextOrMostRecentActiveBooking.cardType,
            bookingDetails: data.nextOrMostRecentActiveBooking.bookingDetails,
            generateBackgroundImageDiv,
            generateOverlayShadeDiv,
        });
    }

    if (data.nextOrMostRecentActiveBooking.cardType === RECENT) {
        return h(RecentBooking, {
            CardContainer,
            BookingDetailsButton: ReusableButton,
            bookingDetails: data.nextOrMostRecentActiveBooking.bookingDetails,
            generateBackgroundImageDiv,
            generateOverlayShadeDiv,
        });
    }

    return h(NoBookingCard, {
        CardContainer,
        FindYourPerfectVacationButton: ReusableButton,
        generateOverlayShadeDiv,
        generateBackgroundImageDiv,
    });
};

export default BookingsCard;
