import glamorous from 'glamorous';
import options from '@designsystem/options';

import { RentalcarCardContainer } from '../components/RentalcarCardContainer';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
} from '../../../styles/waveMediaQueries';

export const CardContainer = glamorous(RentalcarCardContainer)({
    borderRadius: options.radii.small,
    fontFamily: 'Montserrat',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: 0,
    [waveMdMediaQuery]: {
        // override base style from RentalcarCardContainer
        margin: 0,
    },
    [waveLgMediaQuery]: {
        margin: 0,
        flexDirection: 'row',
    },
});
