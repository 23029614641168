import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import moment from 'moment';

import { waveTextMBold, waveTextS } from '../../../styles/waveText';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { getLastOutboundFlight } from '../../../lib/getLastOutboundFlight';
import { formatPriceShort } from '../../myBookings/payment/formatPrice';
import { RentalcarButton } from '../components/RentalcarButton';
import DiscountBadge from '../components/DiscountBadge';

const Container = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const CarDisplay = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    gap: options.space.xs,
    marginBottom: options.space.l,
});

const Option = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const Destination = glamorous.span({
    ...waveTextMBold,
});

const DateAndCarClass = glamorous.span({
    ...waveTextS,
    display: 'flex',
    flexDirection: 'column',
    [waveSmMediaQuery]: {
        flexDirection: 'row',
    },
});

const Pin = glamorous.span({
    display: 'none',
    margin: '0 8px',
    [waveSmMediaQuery]: {
        display: 'inline',
    },
});

const CarPriceContainer = glamorous.div(({ asColumn }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
    gap: options.space.xl,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
        alignItems: asColumn ? 'start' : 'end',
    },
    [waveMdMediaQuery]: {
        flexDirection: asColumn ? 'column-reverse' : 'row',
        alignItems: asColumn ? 'start' : 'end',
    },
}));

const ButtonTextDefault = glamorous.span({
    display: 'block',
});

const OfferInformation = glamorous.div({
    display: 'flex',
    alignItems: 'start',
    gap: options.space.xs,
    flexDirection: 'column',
});

const Price = glamorous.span({
    fontSize: options.fontSizes.l,
    lineHeight: `${options.space.xl}px`,
    fontWeight: 700,
    color: options.colors.blue,
    textWrap: 'nowrap',
});

const OriginalPrice = glamorous.span({
    color: options.colors.gray40,
    fontSize: options.fontSizes['2xs'],
    fontWeight: 500,
    textDecoration: 'line-through',
    textWrap: 'nowrap',
});

const Range = glamorous.span({
    color: options.colors.gray40,
    fontSize: options.fontSizes['2xs'],
    fontWeight: 500,
});

const DATE_FORMAT = 'DD.MM.YYYY';

const PriceAndRange = glamorous.div(({ asRow }) => ({
    display: 'flex',
    flexDirection: asRow ? 'row-reverse' : 'column',
    alignItems: 'flex-start',
    ...(asRow
        ? {
              alignItems: 'center',
              gap: options.space.xs,
          }
        : {}),
}));

const CarImage = glamorous.img({
    width: 64,
    height: 'auto',
    objectFit: 'contain',
    transform: 'scaleX(-1)',
});

const formatPeriod = (pickup, dropoff) =>
    pickup && dropoff
        ? `${pickup.format(DATE_FORMAT)} - ${dropoff.format(DATE_FORMAT)}`
        : '';

const formatPrice = (fromPrice, price, currency, locale) => {
    return `${fromPrice ? 'ab' : ''} ${formatPriceShort(price, currency, locale)}`.trim();
};

const updateUrl = (window) => (url, offerCardType, utmParameter) => {
    const parsed = new window.URL(url);

    parsed.searchParams.append('mhcOfferCardType', offerCardType);
    Object.entries(utmParameter || {}).forEach(([key, value]) =>
        parsed.searchParams.set(key, value),
    );
    return parsed.toString();
};

const updateListingUrl = (window) => (url, utmParameter) => {
    const parsed = new window.URL(url);

    Object.entries(utmParameter || {}).forEach(([key, value]) =>
        parsed.searchParams.set(key, value),
    );

    return parsed.toString();
};

const mapCarClass = (orig) => {
    const carClassTranslation = {
        Mini: 'Kleinstwagen',
        Economy: 'Kleinwagen',
        Compact: 'Kompaktklasse',
        Intermediate: 'Mittelklasse',
        Fullsize: 'Oberklasse',
        Premium: 'Premiumklasse',
        Luxury: 'Premiumklasse',
    };

    return carClassTranslation[orig] || orig;
};

function Offer({
    cardType,
    linkToListing,
    hcTravel,
    search,
    searchData,
    offer,
    context,
    utmParameter,
    hasCampaign,
}) {
    const lastInboundFlight = getLastOutboundFlight(hcTravel);
    const { trackEvent } = context;
    const pickup = moment(searchData.pickup.datetime);
    const dropoff = moment(searchData.dropoff.datetime);
    const rentalDurationDays = Math.max(
        Math.ceil(dropoff.diff(pickup, 'hours') / 24),
        1,
    );

    const range = moment
        .duration(rentalDurationDays, 'days')
        .locale('de')
        .humanize();

    return h(Container, [
        h(CarDisplay, [
            h(CarImage, { src: offer.pictureUrl }),
            h(Option, [
                (offer.destinationName ||
                    lastInboundFlight.arrival.airportCode) &&
                    h(
                        Destination,
                        offer.destinationName ||
                            lastInboundFlight.arrival.airportCode,
                    ),
                h(DateAndCarClass, [
                    h('span', formatPeriod(pickup, dropoff)),
                    offer.carClass &&
                        h('span', [h(Pin, '•'), mapCarClass(offer.carClass)]),
                ]),
            ]),
        ]),
        h(CarPriceContainer, [
            h(
                RentalcarButton,
                {
                    onClick() {
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub - Rentalcar',
                            eventAction: linkToListing
                                ? 'goToListing'
                                : 'goToOffer',
                        });
                    },
                    href: linkToListing
                        ? updateListingUrl(context.window)(
                              search.listingUrl,
                              utmParameter,
                          )
                        : updateUrl(context.window)(
                              offer.offerPageUrl,
                              cardType,
                              utmParameter,
                          ),
                },
                [
                    h(
                        ButtonTextDefault,
                        linkToListing ? 'Mietwagen finden' : 'Mietwagen Buchen',
                    ),
                ],
            ),
            h(OfferInformation, [
                hasCampaign &&
                    h(DiscountBadge, {
                        context,
                        withTooltip: true,
                        couponApplied: offer.discountCouponApplied,
                    }),
                h(PriceAndRange, { asRow: hasCampaign }, [
                    h(
                        Price,
                        formatPrice(
                            linkToListing,
                            offer.priceAmount,
                            offer.priceCurrency,
                            context.config.locale,
                        ),
                    ),
                    hasCampaign
                        ? h(
                              OriginalPrice,
                              `statt ${formatPrice(false, offer.priceAmountFull, offer.priceCurrency)}`,
                          )
                        : h(Range, `für ${range}`),
                ]),
            ]),
        ]),
    ]);
}

export { Offer };
