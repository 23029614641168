import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { useQuery } from '@apollo/client';

import {
    CAR_RENTAL_CASHBACK_ITEM_ID,
    extractAvailableCashbackItem,
} from '../../../../lib/premium/cashback';
import {
    waveTextM,
    waveTextMButton,
    waveTextS,
    waveTextSMedium,
} from '../../../../styles/waveText';
import formatDate from '../../../../lib/formatDate';
import { pxToRem } from '../../../../styles/unitConverter';
import {
    drivebooGradient,
    premiumGradient,
} from '../../../../styles/waveColors';
import {
    waveHeadline2XS,
    waveHeadlineS,
    waveHeadlineXS,
} from '../../../../styles/waveHeadlines';
import useInScreen from '../../../../lib/hooks/useInScreen';
import { extractActivePeriod } from '../../../../lib/premium/membership';
import { clubOffers } from '../../../../queries/clubOffers';
import { noticeError } from '../../../../lib/newrelic';
import handleQueryErrors from '../../../../lib/handleQueryErrors';

const MyPremiumCardContainer = glamorous.div();

const Header = glamorous.div(({ id }) => ({
    background:
        id === CAR_RENTAL_CASHBACK_ITEM_ID ? drivebooGradient : premiumGradient,
    color: options.colors.white,
    padding: `${options.space.l}px ${options.space.xl}px`,
}));

const PremiumLogo = glamorous.img({
    ...waveHeadline2XS,
    width: options.space['8xl'],
});

const PremiumInfoHeader = glamorous.div({
    ...waveTextSMedium,
    color: options.colors.purple,
    textTransform: 'uppercase',
});

const PremiumInfoSubheader = glamorous.div({
    ...waveTextM,
});

const Headline = glamorous.div(({ customCss }) => ({
    ...customCss,
}));

const SubHeadline = glamorous.div({
    fontSize: options.fontSizes.s,
    lineHeight: '24px',
    fontWeight: options.fontWeights.bold,
});

const ValidityHint = glamorous.div({
    ...waveTextM,
});

const CtaButton = glamorous.a({
    display: 'block',
    maxWidth: '160px',
    border: `1px solid ${options.colors.purple}`,
    borderRadius: pxToRem(100),
    padding: `${options.space.xs}px ${options.space['2xs']}px`,
    textAlign: 'center',
    color: options.colors.purple,
    textDecoration: 'none',
    ...waveTextMButton,
    fontWeight: options.fontWeights.bold,

    '&:hover': {
        color: options.colors.purple,
    },
});

const PremiumCardContent = glamorous.div(({ config }) => ({
    backgroundSize: 'contain',
    backgroundColor: options.colors.purple100,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    backgroundImage: `url('${config.assetsPath}/myHolidayCheck/premium_card_bg.png')`,
    padding: `${options.space.l}px ${options.space.xl}px`,
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.m}px`,
}));

const extractExistingMemberCampaign = (data) => {
    if (!data) {
        return null;
    }

    const premiumExistingMemberCampaign = data.clubOffers.items.find(
        ({ id }) => id === 'PREMIUM_EXISTING_MEMBER_CAMPAIGN',
    );

    if (!premiumExistingMemberCampaign) {
        return null;
    }

    return premiumExistingMemberCampaign.detail.campaign;
};

const MyPremiumCard = (
    { activeContract, trackEventForPremium },
    { config, userId },
) => {
    try {
        const { error: clubOffersFetchError, data } = useQuery(clubOffers);

        if (clubOffersFetchError) {
            handleQueryErrors({
                error: clubOffersFetchError,
                userId,
                component: 'MyPremiumCard',
            });
        }
        const [setRef] = useInScreen(() =>
            trackEventForPremium({
                event: {
                    eventCategory: 'Premium-Club',
                    eventAction: 'Mein-Premium-Card-Shown',
                },
            }),
        );

        const activePeriod = extractActivePeriod(
            activeContract.subscriptionPeriods,
        );
        const existingMemberCampaign = extractExistingMemberCampaign(data);

        const { cashbackItem, cashbackItemTypeName, periodEndDateFormatted } =
            extractAvailableCashbackItem(activePeriod);

        return h(MyPremiumCardContainer, { innerRef: setRef }, [
            h(
                Header,
                {
                    id: cashbackItem.id,
                },
                existingMemberCampaign
                    ? [
                          h(
                              Headline,
                              { customCss: waveHeadlineXS },
                              existingMemberCampaign.hero.text,
                          ),
                          h(SubHeadline, existingMemberCampaign.hero.subText),
                          h(
                              ValidityHint,
                              `Bis zum ${new Date(
                                  existingMemberCampaign.timeFrame.endDate,
                              ).toLocaleDateString('de-DE', {
                                  day: '2-digit',
                                  month: '2-digit',
                              })} in Deinem Premium-Bereich`,
                          ),
                      ]
                    : [
                          h(Headline, { customCss: waveHeadlineS }, [
                              cashbackItem.id === CAR_RENTAL_CASHBACK_ITEM_ID &&
                                  h(
                                      'span',
                                      {
                                          style: {
                                              ...waveTextS,
                                          },
                                      },
                                      'bis zu ',
                                  ),
                              `${cashbackItem.detail.cashback.amount}€`,
                          ]),
                          h(SubHeadline, cashbackItemTypeName),
                          h(
                              ValidityHint,
                              `Gültig bis ${periodEndDateFormatted}`,
                          ),
                      ],
            ),
            h(PremiumCardContent, { config }, [
                h(PremiumLogo, {
                    src: `${config.assetsPath}/myHolidayCheck/hc_premium.svg`,
                }),
                h('div', [
                    h(PremiumInfoHeader, 'Mitgliedsnummer'),
                    h(
                        PremiumInfoSubheader,
                        activeContract.subscriptionContractID,
                    ),
                ]),
                h('div', [
                    h(PremiumInfoHeader, 'Mitglied seit'),
                    h(
                        PremiumInfoSubheader,
                        `${formatDate(activeContract.contractStartDate, 'DD.MM.YYYY')} · ` +
                            `${activeContract.currentSubscriptionYear}. Mitgliedsjahr`,
                    ),
                ]),
                h(
                    CtaButton,
                    {
                        href: '/mhc/mein-premium',
                        onClick() {
                            trackEventForPremium({
                                event: {
                                    eventCategory: 'Premium-Club',
                                    eventAction: 'Click-Mein-Premium',
                                    eventLabel: 'Mein-Premium-Card',
                                },
                            });
                        },
                    },
                    'MEIN PREMIUM',
                ),
            ]),
        ]);
    } catch (error) {
        noticeError(error.message, {
            pageName: 'MyHolidayCheck',
            component: 'MyPremiumCard',
        });
        return null;
    }
};

export default MyPremiumCard;

MyPremiumCard.contextTypes = {
    config: PropTypes.object,
    userId: PropTypes.string,
};
