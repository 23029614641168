import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { SectionContainer, SectionTitle } from '../BookingDetails';
import { waveTextM, waveTextMBold } from '../../../../styles/waveText';
import Link from '../../../navigation/Link';
import { RetalCarBookingType } from '../../BookingPropType';
import trackEvent from '../../../../lib/trackEvent';
import { waveSmMediaQuery } from '../../../../styles/waveMediaQueries';

const Container = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.xl}px`,
});

const Text = glamorous.span({
    ...waveTextM,
});

const DocumentList = glamorous.ul({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    rowGap: `${options.space.xl}px`,
    [waveSmMediaQuery]: {
        gap: `${options.space.xl}px`,
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
});

const DocumentListItem = glamorous.li({
    [waveSmMediaQuery]: {
        gridColumn: 'span 2',
    },
});

const DocumentContainer = glamorous(Link)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${options.colors.gray90}`,
    borderRadius: options.radii.small,
    boxSizing: 'border-box',
    padding: `${options.space.m}px`,
    ':hover': {
        '& .file-metadata-container-cls .filename-title-cls': {
            color: options.colors.linkHoverColor,
        },
    },
});

const DocumentInfo = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const FileDescription = glamorous(Text)({
    color: options.colors.gray20,
});

const renderDescription = (fileType) => {
    if (!fileType) {
        return undefined;
    }
    const Description =
        fileType === 'Voucher'
            ? 'Buchungsbetätigung'
            : 'Infos vor der Anmietung';
    return h(FileDescription, Description);
};

const DocumentName = glamorous.span({
    ...waveTextMBold,
    color: options.colors.link,
});

const Document = ({ assetsPath, bookingId, id, name, tpe }) => {
    return h(
        DocumentContainer,
        {
            href: `/mhc/rentalcar-booking-file/${bookingId}/${id}`,
            download: name,
            onClick: () => {
                trackEvent({
                    event: 'event',
                    eventCategory: 'rentalcarBooking',
                    eventAction: 'downloadFile',
                });
            },
        },
        [
            h(DocumentInfo, [h(DocumentName, name), renderDescription(tpe)]),
            h('img', {
                src: `${assetsPath}/myBookings/common-file-download.svg`,
                alt: 'Zum Herunterladen klicken',
            }),
        ],
    );
};

function Files({ booking, myRef, sectionId }, { config: { assetsPath } }) {
    return h(SectionContainer, { id: sectionId, innerRef: myRef }, [
        h(Container, [
            h(SectionTitle, 'Deine Dokumente'),
            h(
                Text,
                'Deine Mietwagen-Unterlagen erhältst Du nach Zahlungseingang von Deinem Mietwagen-Veranstalter (bitte überprüfe ggbfs. auch Dein Email-Postfach und Spam-Ordner).',
            ),
            h(DocumentList, [
                booking.files.map((file) =>
                    h(DocumentListItem, { key: file.id }, [
                        h(Document, {
                            assetsPath,
                            bookingId: booking.id,
                            ...file,
                        }),
                    ]),
                ),
            ]),
        ]),
    ]);
}

Files.displayName = 'TravelDocuments';
Files.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};
Files.propTypes = {
    sectionId: PropTypes.string.isRequired,
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]).isRequired,
    booking: RetalCarBookingType.isRequired,
};
export { Files };
