import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../../styles/waveMediaQueries';
import { waveTextM, waveTextS } from '../../../../styles/waveText';
import { pxToRem } from '../../../../styles/unitConverter';

const NoBookingInfoContainer = glamorous.div({
    padding: `${options.space.s}px`,
    [waveSmMediaQuery]: {
        height: '25%',
        padding: `${options.space.l}px`,
    },
});

const YourBookingsText = glamorous.p({
    fontWeight: 'bold',
});

const PreviousBookingsLinks = glamorous.a({
    textDecoration: 'none',
    fontWeight: 'bold',
    textTransform: 'uppercase',
});

const FindYourPerfectVacationHeader = glamorous.header({
    fontSize: pxToRem(options.fontSizes.m),
    fontWeight: options.fontWeights.bold,
    color: options.colors.white,
    zIndex: 1,
    [waveSmMediaQuery]: {
        fontSize: pxToRem(options.fontSizes.l),
    },
});

const FindYourPerfectVacationSubheader = glamorous.p({
    ...waveTextS,
    color: options.colors.white,
    textAlign: 'center',
    marginTop: `${options.space['2xs']}px`,
    marginRight: `${options.space.l}px`,
    marginBottom: `${options.space.m}px`,
    marginLeft: `${options.space.l}px`,
    [waveSmMediaQuery]: {
        marginRight: `${options.space.xl}px`,
        marginLeft: `${options.space.xl}px`,
    },
    [waveMdMediaQuery]: {
        ...waveTextM,
    },
    zIndex: 1,
});

const NoBookingCard = (
    {
        CardContainer,
        FindYourPerfectVacationButton,
        generateOverlayShadeDiv,
        generateBackgroundImageDiv,
    },
    { config },
) => {
    const OverlayShadeDiv = generateOverlayShadeDiv(0.6);
    const BackgroundImage = generateBackgroundImageDiv({
        height: '70%',
        backgroundImage: `url(${config.assetsPath}/myHolidayCheck/travel-inspiration.jpeg)`,
        [waveSmMediaQuery]: {
            height: '75%',
        },
    });

    return h(CardContainer, [
        h(NoBookingInfoContainer, [
            h(YourBookingsText, 'Deine Buchungen'),
            h('p', 'Du hast momentan keine Buchungen'),
            h(
                PreviousBookingsLinks,
                {
                    href: '/mhc/meine-buchungen',
                },
                'Bisherige Buchungen >',
            ),
        ]),
        h(BackgroundImage, [
            h(OverlayShadeDiv),
            h(FindYourPerfectVacationHeader, 'Finde Deinen perfekten Urlaub'),
            h(
                FindYourPerfectVacationSubheader,
                'Jetzt endlich Urlaub in der Sonne buchen',
            ),
            h(
                FindYourPerfectVacationButton,
                {
                    href: '/deals',
                },
                'Urlaub Finden',
            ),
        ]),
    ]);
};

export default NoBookingCard;

NoBookingCard.propTypes = {
    CardContainer: PropTypes.func,
    FindYourPerfectVacationButton: PropTypes.func,
    generateOverlayShadeDiv: PropTypes.func,
    generateBackgroundImageDiv: PropTypes.func,
};

NoBookingCard.contextTypes = {
    config: PropTypes.object,
};
