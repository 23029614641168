import moment from 'moment';

export const transformDate = (date) =>
    moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');

export const getBackgroundImage = (hotelId) => {
    if (hotelId) {
        return `url(https://www.holidaycheck.de/main-photo-redirect/${hotelId}/w_1920,h_1080,c_fill,q_auto,f_auto)`;
    }

    return `url(https://www.holidaycheck.de/public/assets/img/hotel-index-no-picture-placeholder.svg)`;
};
