import gql from 'graphql-tag';

export const nextOrMostRecentActiveBooking = gql`
    query {
        nextOrMostRecentActiveBooking {
            cardType
            bookingDetails {
                bookingId
                hotelId
                startDate
                endDate
                hotelName
                city
            }
        }
    }
`;
