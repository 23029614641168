import * as R from 'ramda';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';
import { useNavigate } from 'react-router-dom';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import NavigationBar from '../navigation/NavigationBar';
import Page from '../Page';
import ProfilePictureSetting from './settings/ProfilePictureSetting';
import SettingsForm from './settings/SettingsForm';
import { profileAndCountries } from '../../queries/profile';
import { waveSmMediaQuery } from '../../styles/waveMediaQueries';
import { noticeError } from '../../lib/newrelic';

const baseUrl =
    'https://media-cdn.holidaycheck.com/image/upload/v1705326670/campaigns/newsletter';
const ImageContainer = glamorous.div({
    width: '100%',
    backgroundImage: `url('${baseUrl}/profile-header-xs.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 216,
    margin: 0,
    [waveSmMediaQuery]: {
        backgroundImage: `url('${baseUrl}/profile-header-lg.png')`,
        height: 402,
    },
});

const PageSection = glamorous.div({
    padding: '20px',
    margin: 'auto',
    fontFamily: options.fonts.default,
    width: '90%',
    marginTop: -140,
    [waveSmMediaQuery]: {
        width: '53%',
        marginTop: -220,
    },
});

const FormSection = glamorous.section({
    alignItems: 'center',
    margin: 'auto',
    maxWidth: '100%',
    flexDirection: 'column',
});

const ProfileOwnerName = glamorous.div({
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '90%',
    fontFamily: options.fonts.default,
    fontWeight: options.fontWeights.extraBold,
    fontSize: options.fontSizes.xl,
    paddingBottom: options.space.xl,
    borderBottom: `1px solid ${options.colors.gray90}`,
});

const parseProfile = R.evolve({
    dateOfBirth: R.unless(R.isNil, (str) => new Date(`${str}T00:00:00.000Z`)),
    salutation: R.defaultTo(''),
});

const getProfile = ({ loading, error, data }) => {
    return !loading && !error ? parseProfile(data.profile) : null;
};

const SettingsPage = (props, { userId }) => {
    const navigate = useNavigate();
    return h(
        Page,
        {
            headline: 'Meine Einstellungen',
            pagename: '/mhc/profile',
        },
        [
            h(NavigationBar, { currentPage: '/mhc/mein-profil' }),
            h(ImageContainer),
            h(
                Query,
                {
                    fetchPolicy: 'cache-and-network',
                    query: profileAndCountries,
                },
                // eslint-disable-next-line complexity
                ({ loading, error, data, refetch }) => {
                    if (error) {
                        noticeError(error, {
                            component: 'SettingsPage',
                            userId,
                        });
                        if (
                            error.networkError &&
                            error.networkError.result &&
                            error.networkError.result.errors
                        ) {
                            error.networkError.result.errors.forEach((e) =>
                                noticeError(e, {
                                    component: 'SettingsPage',
                                    userId,
                                }),
                            );
                        }
                    }
                    const profile = getProfile({ loading, error, data });
                    const profileOwner =
                        profile &&
                        (profile.familyName === null &&
                        profile.givenName === null
                            ? 'Dein Name'
                            : `${profile.givenName || ''} ${profile.familyName || ''}`);

                    return h([
                        h(PageSection, [
                            h(
                                'div',
                                {
                                    style: {
                                        backgroundColor: options.colors.white,
                                        borderTopLeftRadius:
                                            options.radii.small,
                                        borderTopRightRadius:
                                            options.radii.small,
                                        boxShadow: options.shadows.raised,
                                        paddingTop: options.space.l,
                                    },
                                },
                                [
                                    h(ProfilePictureSetting, {
                                        picture: profile
                                            ? profile.profilePicture
                                            : null,
                                        onDeletedProfilePicture: refetch,
                                        navigate,
                                    }),
                                    h(ProfileOwnerName, `${profileOwner}`),
                                ],
                            ),
                            h(FormSection, [
                                h([
                                    h(SettingsForm, {
                                        profile: profile || null,
                                        countries:
                                            !loading && !error
                                                ? data.countries
                                                : null,
                                    }),
                                ]),
                            ]),
                        ]),
                    ]);
                },
            ),
        ],
    );
};

SettingsPage.contextTypes = {
    userId: PropTypes.string,
};
export default SettingsPage;
