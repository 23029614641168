import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { Calendar, PackageTrip, Thumb } from '@designsystem/icons';

import { transformDate, getBackgroundImage } from './utils';
import { pxToRem } from '../../../../styles/unitConverter';
import { waveHeadlineXS } from '../../../../styles/waveHeadlines';
import { tinyFont } from '../../../../styles/fonts';
import buttons from '../../../../styles/buttons';
import { waveSmMediaQuery } from '../../../../styles/waveMediaQueries';

const BookingCity = glamorous.header({
    ...waveHeadlineXS,
    color: options.colors.white,
});

const HotelAndDatesContainer = glamorous.div({
    ...tinyFont,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: options.colors.white,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
    },
});

const ChildHotelContainer = glamorous.div({
    display: 'flex',
    alignItems: 'center',
});

const ChildDatesContainer = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    marginTop: '-8px',
    marginBottom: `${options.space.xs}px`,
    [waveSmMediaQuery]: {
        marginTop: '0px',
        marginBottom: '0px',
        marginLeft: `${options.space.xs}px`,
    },
});

const ReviewContainer = glamorous.div({
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: `0px ${options.space.s}px`,
    [waveSmMediaQuery]: {
        padding: '0px',
    },
});

const ReviewText = glamorous.p({
    fontWeight: 800,
});

const ReviewTripLink = glamorous.a({
    ...buttons.blueButton,
});

const BookingDetailsContainer = glamorous.div({
    zIndex: 1,
    textAlign: 'center',
    color: options.colors.white,
});

const RecentBooking = ({
    CardContainer,
    bookingDetails,
    BookingDetailsButton,
    generateBackgroundImageDiv,
    generateOverlayShadeDiv,
}) => {
    const BackgroundImage = generateBackgroundImageDiv({
        height: '80%',
        justifyContent: 'flex-end',
        backgroundImage: getBackgroundImage(bookingDetails.hotelId),
    });
    const OverlayShadeDiv = generateOverlayShadeDiv(0.4);
    const startAndEndDate = `${transformDate(bookingDetails.startDate)} - ${transformDate(bookingDetails.endDate)}`;

    return h(CardContainer, [
        h(BackgroundImage, [
            h(OverlayShadeDiv),
            h(BookingDetailsContainer, [
                h(BookingCity, bookingDetails.city),
                h(HotelAndDatesContainer, [
                    h(ChildHotelContainer, [
                        h(PackageTrip, {
                            style: {
                                marginRight: '3px',
                            },
                        }),
                        h('p', bookingDetails.hotelName),
                    ]),
                    h(ChildDatesContainer, [
                        h(Calendar, {
                            style: {
                                marginRight: '3px',
                            },
                        }),
                        h('p', startAndEndDate),
                    ]),
                ]),
                h(
                    BookingDetailsButton,
                    {
                        href: '/mhc/meine-buchungen',
                    },
                    'BuchungsDetails',
                ),
            ]),
        ]),
        h(ReviewContainer, [
            h(ReviewText, 'Wie hat dir dein Urlaub gefallen?'),
            h(
                ReviewTripLink,
                {
                    href: `/wcf/hotelreview/variant?hotelId=${bookingDetails.hotelId}`,
                },
                [
                    h(Thumb, {
                        style: {
                            marginRight: pxToRem(3),
                        },
                    }),
                    'Bewerten',
                ],
            ),
        ]),
    ]);
};

export default RecentBooking;

RecentBooking.propTypes = {
    BookingDetailsButton: PropTypes.func,
    CardContainer: PropTypes.func,
    bookingDetails: PropTypes.exact({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        hotelName: PropTypes.string,
        city: PropTypes.string,
        hotelId: PropTypes.string,
        bookingId: PropTypes.string,
        __typename: PropTypes.string,
    }),
    generateBackgroundImageDiv: PropTypes.func,
    generateOverlayShadeDiv: PropTypes.func,
};
