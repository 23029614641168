import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';

import ProfilePictureTrigger from './ProfilePictureTrigger';
import { profilePictureIsFallback as profilePictureIsFallbackQuery } from '../../queries/profile';
import { noticeError } from '../../lib/newrelic';

const ProfilePictureTriggerQuery = (props, { userId }) =>
    h(
        Query,
        {
            fetchPolicy: 'cache-and-network',
            query: profilePictureIsFallbackQuery,
        },
        ({ loading, data, error }) => {
            if (
                !loading &&
                data &&
                data.profile &&
                data.profile.profilePicture.isFallback
            ) {
                return h(ProfilePictureTrigger);
            }

            if (error) {
                noticeError(error.message, {
                    component: 'ProfilePictureTriggerQuery',
                    userId,
                });
            }
            return null;
        },
    );

ProfilePictureTriggerQuery.contextTypes = {
    userId: PropTypes.string,
};

export default ProfilePictureTriggerQuery;
