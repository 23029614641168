import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { getImageName } from './RentalcarOfferCard/config';
import { Offer } from './RentalcarOfferCard/Offer';
import { Loading } from './RentalcarOfferCard/Loading';
import { RentalcarButton } from './components/RentalcarButton';
import { CardContainer } from './RentalcarOfferCard/CardContainer';
import { waveTextS } from '../../styles/waveText';
import { Content } from './RentalcarOfferCard/Content';
import { renderImage } from './RentalcarOfferCard/renderImage';
import { renderTitleAndAdvantages } from './RentalcarOfferCard';

const NoOfferContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
});

const NoOfferFoundText = glamorous.p({
    ...waveTextS,
    color: options.colors.blue,
    margin: 0,
});

function RentalcarSearchOffer({
    advantages,
    isLoading,
    offer,
    cardConfigKey,
    hcTravel,
    search,
    searchData,
    context,
    utmParameter,
    hasCampaign,
    offerCardTitle,
}) {
    return h(CardContainer, [
        h(Content, [
            h(
                renderTitleAndAdvantages({
                    advantages,
                    title: offerCardTitle,
                }),
            ),
            isLoading && h(Loading, { isFinished: !isLoading }),
            !isLoading &&
                offer &&
                h(Offer, {
                    cardType: cardConfigKey,
                    linkToListing: cardConfigKey === 'default',
                    hcTravel,
                    search,
                    searchData,
                    offer,
                    context,
                    utmParameter,
                    hasCampaign,
                }),
            !isLoading &&
                !offer &&
                h(NoOfferContainer, [
                    h(
                        NoOfferFoundText,
                        'Wir haben kein aktuelles Angebot gefunden.',
                    ),
                    h(
                        RentalcarButton,
                        {
                            href: `/mietwagen?utm_source=mHC&utm_medium=Hub&utm_campaign=noOfferFoundRedirect`,
                        },
                        'Mietwagen finden',
                    ),
                ]),
        ]),
        h(
            renderImage(
                cardConfigKey,
                `${context.config.assetsPath}/hcMietwagen/${getImageName(cardConfigKey)}`,
            ),
        ),
    ]);
}

export { RentalcarSearchOffer };
