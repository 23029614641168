import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import moment from 'moment';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    getAdvantages,
    getCardConfigKey,
} from '../rentalcarBookingCard/RentalcarOfferCard/config';
import useRentalcarSearch from '../rentalcarBookingCard/useRentalcarSearch';
import useSearchOffer from '../rentalcarBookingCard/useSearchOffer';
import { RentalcarSearchOffer } from '../rentalcarBookingCard/RentalcarSearchOffer';
import BookingSectionHeadline from './BookingSectionHeadline';
import {
    getWorthFromCampaign,
    useCampaignState,
} from '../rentalcarBookingCard/useCurrentCampaign';
import { getCardTitles } from '../myMietwagen/hub/utils/getCardTitles';
import DiscountBadge from '../rentalcarBookingCard/components/DiscountBadge';

const RentalcarSearchOfferContainer = glamorous.div({
    borderRadius: options.radii.small,
    border: `1px solid ${options.colors.gray90}`,
});

const RentalCar = ({ setWillRender, booking, myRef }, context) => {
    useEffect(() => setWillRender(true), []);

    const cardConfigKey = getCardConfigKey(booking.travellers);
    const advantages = getAdvantages(cardConfigKey);

    // Must be memoized value because const b = {booking} would always recreate the variable
    // and therefore re-fetch useRentalcarSearch and curse an infinite loop!
    const memoizedBooking = useMemo(() => ({ booking }), [booking]);

    const { search, searchData } = useRentalcarSearch(context, memoizedBooking);
    const { offer, isLoading } = useSearchOffer(
        search,
        memoizedBooking,
        false,
        context,
    );
    const { currentCampaign, hasCampaign } = useCampaignState(context, offer);
    const campaignWorth = getWorthFromCampaign(
        currentCampaign,
        context.config.locale,
    );
    const { defaultOfferCardTitle } = getCardTitles({
        profile: undefined,
        hasCampaign,
        campaignWorth,
        isHcmHub: false,
        locale: context.config.locale,
    });

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Mietwagen buchen'),
        h(RentalcarSearchOfferContainer, [
            h(RentalcarSearchOffer, {
                advantages,
                isLoading,
                offer,
                cardConfigKey,
                hcTravel: memoizedBooking,
                search,
                searchData,
                context,
                utmParameter: {
                    // prettier-ignore
                    'utm_source': 'mHC', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                    // prettier-ignore
                    'utm_medium': 'BookingDetails', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                    // prettier-ignore
                    'utm_campaign': 'PackageYesCarNo', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                },
                hasCampaign,
                offerCardTitle: defaultOfferCardTitle,
            }),
        ]),
    ]);
};

RentalCar.propTypes = {
    setWillRender: PropTypes.func.isRequired,
    utmParameter: PropTypes.string,
    booking: PropTypes.object.isRequired,
};

RentalCar.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
    window: PropTypes.object.isRequired,
};

RentalCar.displayName = 'BookRentalcar';

RentalCar.displayWhen = (booking) => {
    return (
        moment(booking.startDate).isAfter(moment()) &&
        booking.travelkind === 'PACKAGE' &&
        booking.bookingType !== 'CANCELLATION'
    );
};

RentalCar.menuBadge = DiscountBadge;

export default RentalCar;
