import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import { useEffect, useState } from 'react';
import { Counter } from '@designsystem/icons';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import { getCountdownValues } from '../../../../Countdown';
import { pxToRem } from '../../../../../styles/unitConverter';
import { secondaryFont } from '../../../../../styles/fonts';

const { headline2XS } = secondaryFont;

const CounterContainer = glamorous.div({
    display: 'flex',
    color: options.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
});

const CountContainer = glamorous.div({
    color: options.colors.white,
    minWidth: pxToRem(20),
    fontWeight: 800,
});

const Count = glamorous.div(headline2XS, {
    marginTop: pxToRem(5),
    marginRight: pxToRem(10),
    display: 'inline-block',
});

const renderCountDown = (startDate, currentDate) => {
    const values = getCountdownValues(startDate, currentDate);

    return h(CounterContainer, [
        h(Counter, {
            style: {
                marginRight: pxToRem(3),
            },
        }),
        values.map(({ value, unit }, index) =>
            h(CountContainer, { key: index }, [h(Count, value + unit)]),
        ),
    ]);
};

const SmallCountdown = ({ startDate }, { window, getCurrentDate }) => {
    const currentDate = getCurrentDate();
    const setTime = useState(0)[1];

    useEffect(() => {
        const interval = window.setInterval(
            () => setTime((time) => time + 1),
            1000,
        );

        return () => window.clearInterval(interval);
    });

    return renderCountDown(startDate, currentDate);
};

export default SmallCountdown;

SmallCountdown.propTypes = {
    startDate: PropTypes.string.isRequired,
};

SmallCountdown.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
    window: PropTypes.object.isRequired,
};
