import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';

const ImageContainer = glamorous.div({
    position: 'relative',
    width: '100%',
    [waveLgMediaQuery]: {
        flex: '0 0 auto',
        width: '195px',
        height: 'auto',
    },
});

const Image = glamorous.img(({ cardType }) => ({
    width: '100%',
    maxHeight: '150px',
    height: 'auto',
    display: 'block',
    objectFit: 'cover',
    borderWidth: `0 0 1px 0`,
    borderColor: options.colors.white,
    borderStyle: 'solid',
    borderRadius: `${options.radii.small} ${options.radii.small} 0 0`,
    ...(cardType !== 'default'
        ? {
              objectPosition: '50% 43%',
              [waveSmMediaQuery]: { objectPosition: '50% 34%' },
              [waveMdMediaQuery]: { objectPosition: '70% 40%' },
          }
        : {
              objectPosition: '50% 83%',
              [waveSmMediaQuery]: { objectPosition: '50% 74%' },
              [waveMdMediaQuery]: { objectPosition: '50% 73%' },
          }),
    [waveLgMediaQuery]: {
        position: 'absolute',
        maxHeight: 'unset',
        height: '100%',
        maxWidth: '236px',
        borderRadius: options.radii.small,
        borderWidth: `0 0 0 1px`,
        transform: cardType !== 'default' ? `scaleX(-1)` : null,
    },
}));

const WaveMobile = h(
    glamorous.svg({
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        [waveLgMediaQuery]: {
            display: 'none',
        },
    }),
    {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 335 30',
    },
    [
        h('path', {
            d: 'M335 29.9877H334.004C329.306 29.8582 308.518 28.6648 282.011 24.3773C251.981 19.5201 217.978 11.8399 189.252 10.8706C131.692 8.92813 121.184 17.2429 87.1505 17.2429C53.1166 17.2429 0 0 0 0V29.9877H334.004C334.628 30.0049 334.968 30.0033 335 29.9877Z',
            fill: 'white',
        }),
    ],
);

const WaveDesktop = h(
    glamorous.svg({
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        bottom: '-1px',
        width: 'auto',
        display: 'none',
        height: '102%',
        [waveLgMediaQuery]: {
            display: 'block',
        },
    }),
    {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '63',
        height: '359',
        viewBox: '0 0 63 359',
        fill: 'none',
    },
    [
        h('path', {
            d: 'M0.0200039 2.45581L0.0187297 2.73114e-06L23.5 1.70474e-06C23.5 1.70474e-06 5.02986 44.5 13 75C20.9701 105.5 39.232 113 43.5 160.5C47.768 208 29.5 231.777 29.5 268C29.5 304.223 62.5 359 62.5 359L0.0200195 359L0.0187297 2.73114e-06C0.0187297 2.73114e-06 -0.0242465 2.48941 0.0200039 2.45581Z',
            fill: 'white',
        }),
    ],
);

export const renderImage = (cardType, imageSource) => [
    h(ImageContainer, [
        h(Image, {
            cardType,
            src: imageSource,
        }),
        WaveMobile,
        WaveDesktop,
    ]),
];
