const SECTION_TO_INDEX_ITEM_TEXT = {
    TravelInformation: 'Reiseübersicht',
    PaymentInformation: 'Zahlungsinformation',
    FlightInformation: 'Flug',
    HotelInformation: 'Hotel',
    TravelTourOperator: 'Reiseveranstalter',
    TravelDocuments: 'Dokumente',
    ContractualPartner: 'Vertragspartner',
    Travellers: 'Reiseteilnehmer',
    SpecialRequest: 'Deine Wünsche',
    InsuranceUpsell: 'Urlaub absichern',
    Rebooking: 'Urlaub umbuchen',
    Cancellation: 'Urlaub stornieren',
    FAQSection: 'FAQ',
    Rentalcar: 'Mietwagen',
    BookRentalcar: 'Mietwagen buchen',
    RentalcarCancellation: 'Mietwagen stornieren',
};

export default (section) => {
    return SECTION_TO_INDEX_ITEM_TEXT[section];
};
